var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('el-dialog',{attrs:{"visible":_vm.show,"title":"Upload Document","width":"550px"},on:{"update:visible":function($event){_vm.show=$event},"close":_vm.closePopup}},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.documentData,"status-icon":"","label-width":"150px"}},[_c('el-form-item',{staticClass:"mb-1",attrs:{"label":"Document Title"}},[_c('el-input',{attrs:{"placeholder":"Please Document Name","readonly":""},model:{value:(_vm.documentData.title),callback:function ($$v) {_vm.$set(_vm.documentData, "title", $$v)},expression:"documentData.title"}}),(
            _vm.getRequestedDocumentAddingErrors &&
            _vm.getRequestedDocumentAddingErrors.title
          )?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getRequestedDocumentAddingErrors.title))]):_vm._e(),(
            _vm.getRequestedDocumentAddingErrors &&
            _vm.getRequestedDocumentAddingErrors.critical_error
          )?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getRequestedDocumentAddingErrors.critical_error))]):_vm._e()],1),_c('el-form-item',{staticClass:"mb-1",attrs:{"label":"Issuing Authority"}},[_c('el-input',{attrs:{"placeholder":"Please Enter Issuing Authority"},model:{value:(_vm.documentData.issuing_authority),callback:function ($$v) {_vm.$set(_vm.documentData, "issuing_authority", $$v)},expression:"documentData.issuing_authority"}}),(
            _vm.getRequestedDocumentAddingErrors &&
            _vm.getRequestedDocumentAddingErrors.issuing_authority
          )?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getRequestedDocumentAddingErrors.issuing_authority))]):_vm._e()],1),_c('el-form-item',{staticClass:"mb-1",attrs:{"label":"Document Number"}},[_c('el-input',{attrs:{"placeholder":"Please Enter Document Number"},model:{value:(_vm.documentData.document_number),callback:function ($$v) {_vm.$set(_vm.documentData, "document_number", $$v)},expression:"documentData.document_number"}}),(
            _vm.getRequestedDocumentAddingErrors &&
            _vm.getRequestedDocumentAddingErrors.document_number
          )?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getRequestedDocumentAddingErrors.document_number))]):_vm._e()],1),_c('el-form-item',{staticClass:"mb-1",attrs:{"label":"Valid From"}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","placeholder":"Pick a date"},model:{value:(_vm.documentData.valid_from),callback:function ($$v) {_vm.$set(_vm.documentData, "valid_from", $$v)},expression:"documentData.valid_from"}}),(
            _vm.getRequestedDocumentAddingErrors &&
            _vm.getRequestedDocumentAddingErrors.valid_from
          )?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getRequestedDocumentAddingErrors.valid_from))]):_vm._e()],1),_c('el-form-item',{staticClass:"mb-1",attrs:{"label":"Expiration Date"}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","placeholder":"Pick a date"},model:{value:(_vm.documentData.expires_at),callback:function ($$v) {_vm.$set(_vm.documentData, "expires_at", $$v)},expression:"documentData.expires_at"}}),(
            _vm.getRequestedDocumentAddingErrors &&
            _vm.getRequestedDocumentAddingErrors.expires_at
          )?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getRequestedDocumentAddingErrors.expires_at))]):_vm._e()],1),_c('el-form-item',{staticClass:"mb-1",attrs:{"label":"Upload Document"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"","on-change":_vm.upload,"show-file-list":false,"auto-upload":false}},[_c('i',{staticClass:"el-icon-upload2"})]),(_vm.selectedFile)?_c('div',{staticClass:"filename"},[_c('a',{staticClass:"el-upload-list__item-name"},[_c('i',{staticClass:"el-icon-document"}),_vm._v(" "+_vm._s(_vm.selectedFile.name)+" ")])]):_vm._e(),(_vm.fileError)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.fileError))]):_vm._e()],1)],1),_c('template',{slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.saveDocument}},[_vm._v("Save Document")]),_c('el-button',{on:{"click":_vm.closePopup}},[_vm._v("Cancel")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }